import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/root/root';
import ErrorNotFound from './routes/errorNotFound'
import IPcalc from './routes/ipcalc/ipcalc';
import ConfigGen from './routes/config-gen/configGen'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorNotFound />,
    },
    {
        path: '/misc/ip-calc',
        element: <IPcalc />,
    },
    {
        path: '/misc/config-gen',
        element: <ConfigGen />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);