import './navbar.css';
import React from 'react';
const Navbar = () => {
    return (
        <nav>
            <a href='/' className='standaloneLink'>Home</a>
            <div className='dropdown'>
                <button>Misc</button>
                <div className='dropdownContent'>
                    <a href='/misc/ip-calc'>IP calculator</a>
                    <a href='/misc/config-gen'>Cisco config generator</a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar