import { /* useEffect, */useState } from 'react'
import Navbar from '../../components/navbar/navbar'
import './configGen.css';
import CopyToClipboard from 'react-copy-to-clipboard';

const ConfigGen = () => {
    const [ sshInputContainerVisibility, sshInputContainerVisibilitySet ] = useState(false);
    const [ sshVlanContainerVisibility, sshVlanContainerVisibilitySet ] = useState(false);
    const [ copyBtnContainerVisibility, copyBtnContainerVisibilitySet ] = useState(false);

    const [ hostnameInput, hostnameInputSet ] = useState('');
    const [ passwordInput, passwordInputSet ] = useState('');
    const [ secretInput, secretInputSet ] = useState('');
    const [ conPassInput, conPassInputSet ] = useState('');
    const [ encryptionInput, encryptionInputSet ] = useState(false);
    const [ loggSConInput, loggSConInputSet ] = useState(false);
    const [ loggSVtyInput, loggSVtyInputSet ] = useState(false);
    const [ noLookupInput, noLookupInputSet ] = useState(false);

    const [ domainInput, domainInputSet ] = useState('');
    const [ usernameInput, usernameInputSet ] = useState('');
    const [ sshPasswordInput, sshPasswordInputSet ] = useState('');
    const [ gwInput, gwInputSet ] = useState('');
    const [ vlanIpInput, vlanIpInputSet ] = useState('');
    const [ vlanMaskInput, vlanMaskInputSet ] = useState('');
    
    const [ output, outputSet ] = useState('');
    // const [ configName, configNameSet ] = useState('');
    // const [ savedConfigs, savedConfigsSet ] = useState(localStorage.getItem('savedConfigs'));

    const handleSubmit = (e) => {
        e.preventDefault();

        let _output = 'en\nconf t';
        
        if (hostnameInput) _output = `${_output}\nhostname ${hostnameInput}`;
        if (passwordInput) _output = `${_output}\nenable password ${passwordInput}`;
        if (secretInput) _output = `${_output}\nenable secret ${secretInput}`;
        if (conPassInput) _output = `${_output}\nline console 0\npassword ${conPassInput}\nlogin\nexit`;
        if (encryptionInput) _output = `${_output}\nservice password-encryption`;
        if (loggSConInput) _output = `${_output}\nline console 0\nlogging synchronous\nexit`;
        if (loggSVtyInput) _output = `${_output}\nline vty 0 4\nlogging synchronous\nexit`;
        if (noLookupInput) _output = `${_output}\nno ip domain-lookup`;

        if (domainInput) _output = `${_output}\nip domain-name ${domainInput}\ncrypto key generate rsa general-keys modulus 1024`;
        if (usernameInput) _output = `${_output}\nusername ${usernameInput} password ${sshPasswordInput}\nline vty 0 4\ntransport input ssh\nlogin local`;
        if (gwInput) _output = `${_output}\nip default-gateway ${gwInput}\nip ssh version 2`;
        if (vlanIpInput) _output = `${_output}\nint vlan 1\nip address ${vlanIpInput} ${vlanMaskInput}\nno shutdown\nexit`;

        outputSet(_output );
        copyBtnContainerVisibilitySet(true);
    }

    const handleSshContVisInput = () => {
        sshInputContainerVisibilitySet(!sshInputContainerVisibility);
    }

    const handleOnSwInput = () => {
        sshVlanContainerVisibilitySet(!sshVlanContainerVisibility);
    }

    const handleCopyBtn = () => {
        alert('Copied!');
    }

    // const handleSave = (e) => {
    //     e.preventDefault();

    //     if (!localStorage.getItem('savedConfigs')) localStorage.setItem('savedConfigs', '')

    //     const existingSavedConfigs = localStorage.getItem('savedConfigs');
    //     localStorage.setItem('savedConfigs', `${existingSavedConfigs}\n${configName}`)
    // }

    // useEffect(() => {
    //     savedConfigsSet(localStorage.getItem('savedConfigs'));
    // }, [])

    return (
        <>
            <Navbar />
            <main className='configGenMain'>
                <form onSubmit={handleSubmit}>
                    <div className='singleInputContainer firstSingleInputContainer'>
                        <label htmlFor='hostnameInput'>Hostname</label>
                        <input onChange={(e) => hostnameInputSet(e.target.value)} id='hostnameInput' type='text' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='passwordInput'>Password</label>
                        <input onChange={(e) => passwordInputSet(e.target.value)} id='passwordInput' type='text' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='secretInput'>Secret</label>
                        <input onChange={(e) => secretInputSet(e.target.value)} id='secretInput' type='text' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='conPassInput'>Console password</label>
                        <input onChange={(e) => conPassInputSet(e.target.value)} id='conPassInput' type='text' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='encryptionInput'>Global encryption</label>
                        <input onChange={() => encryptionInputSet(true)} id='encryptionInput' type='checkbox' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='loggSConInput'>Logging synchronous (console)</label>
                        <input onChange={() => loggSConInputSet(true)} id='loggSConInput' type='checkbox' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='loggSVtyInput'>Logging synchronous (vty 0 4)</label>
                        <input onChange={() => loggSVtyInputSet(true)} id='loggSVtyInput' type='checkbox' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='noLookupInput'>No domain lookup</label>
                        <input onChange={() => noLookupInputSet(true)} id='noLookupInput' type='checkbox' />
                    </div>
                    <div className='singleInputContainer'>
                        <label htmlFor='sshContVisInput'>SSH (vty 0 4)</label>
                        <input onChange={() => handleSshContVisInput()} id='sshContVisInput' type='checkbox' />
                    </div>

                    {sshInputContainerVisibility && 
                        <>
                            <div className='singleInputContainer'>
                                <label htmlFor='domainInput'>Domain</label>
                                <input onChange={(e) => domainInputSet(e.target.value)} id='domainInput' type='text' />
                            </div>
                            <div className='singleInputContainer'>
                                <label htmlFor='useusernameInputrnameInput'>Username</label>
                                <input onChange={(e) => usernameInputSet(e.target.value)} id='usernameInput' type='text' />
                            </div>
                            <div className='singleInputContainer'>
                                <label htmlFor='sshPasswordInput'>Password</label>
                                <input onChange={(e) => sshPasswordInputSet(e.target.value)} id='sshPasswordInput' type='text' />
                            </div>
                            <div className='singleInputContainer'>
                                <label htmlFor='onSwInput'>On a switch</label>
                                <input onChange={() => handleOnSwInput()} id='onSwInput' type='checkbox' />
                            </div>
                            
                            {sshVlanContainerVisibility &&
                                <>
                                    <div className='singleInputContainer'>
                                        <label htmlFor='vlanIpInput'>IP on vlan 1</label>
                                        <input onChange={(e) => vlanIpInputSet(e.target.value)} id='vlanIpInput' type='text' />
                                    </div>
                                    <div className='singleInputContainer'>
                                        <label htmlFor='vlanMaskInput'>Mask on vlan 1</label>
                                        <input onChange={(e) => vlanMaskInputSet(e.target.value)} id='vlanMaskInput' type='text' />
                                    </div>
                                    <div className='singleInputContainer'>
                                        <label htmlFor='gwInput'>Gateway</label>
                                        <input onChange={(e) => gwInputSet(e.target.value)} id='gwInput' type='text' />
                                    </div>
                                </>
                            }
                        </>
                    }
                
                    <div className='btnsContainer'>
                        <input id='submitBtn' type='submit' value='Create config' />
                        {copyBtnContainerVisibility &&
                            <CopyToClipboard text={output + '\n'} onCopy={handleCopyBtn}>
                                <button>Copy</button>
                            </CopyToClipboard>
                        }
                    </div>
                </form>
                <div className='outputContainer'>
                    <pre>{output}</pre>
                    {/* <form onSubmit={handleSave}>
                        <label htmlFor='configName'>Name: </label>
                        <input onChange={(e) => configNameSet(e.target.value)} id='configName' type='text' required />
                        <input type='submit' value='Save' />
                    </form> */}
                </div>
                {/* <div className='savedContainer'>
                    <h2>Saved configurations:</h2>
                    {savedConfigs}
                </div> */}
            </main>
        </>
    )
}
export default ConfigGen;