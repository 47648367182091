import './root.css';
import React, { useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import jupiter from '../../img/jupiter.png'

const Root = () => {
    const [ miscContainerVisibility, miscContainerVisibilitySet ] = useState(false);
    // const [ gamesContainerVisibility, gamesContainerVisibilitySet ] = useState(false);
    const [ linksContainerVisibility, linksContainerVisibilitySet ] = useState(false);
    const [ miscBtnActiveCSS, miscBtnActiveCSSSet ] = useState({});
    // const [ gamesBtnActiveCSS, gamesBtnActiveCSSSet ] = useState({});
    const [ linksBtnActiveCSS, linksBtnActiveCSSSet ] = useState({});

    const handleMiscBtn = () => {
        miscContainerVisibilitySet(!miscContainerVisibility);
        // gamesContainerVisibilitySet(false);
        linksContainerVisibilitySet(false);

        if (miscContainerVisibility) {
            miscBtnActiveCSSSet({});
            return;
        }

        // gamesBtnActiveCSSSet({});
        linksBtnActiveCSSSet({});
        miscBtnActiveCSSSet({
            backgroundColor: '#110b8451',
            fontSize: 'calc(5rem + 2vh - 2vw)',
        })
    }

    // const handleGamesBtn = () => {
    //     gamesContainerVisibilitySet(!gamesContainerVisibility);
    //     miscContainerVisibilitySet(false);

    //     if (gamesContainerVisibility) {
    //         gamesBtnActiveCSSSet({});
    //         return;
    //     }

    //     miscBtnActiveCSSSet({});
    //     gamesBtnActiveCSSSet({
    //         backgroundColor: '#110b8451',
    //         fontSize: 'calc(5rem + 2vh - 2vw)',
    //     })
    // }

    const handleLinksBtn = () => {
        linksContainerVisibilitySet(!linksContainerVisibility);
        miscContainerVisibilitySet(false);

        if (linksContainerVisibility) {
            linksBtnActiveCSSSet({});
            return;
        }

        miscBtnActiveCSSSet({});
        linksBtnActiveCSSSet({
            backgroundColor: '#110b8451',
            fontSize: 'calc(5rem + 2vh - 2vw)',
        })
    }
    return (
        <>
            <main className='rootMain'>
                <Parallax pages={1.5}>
                    <ParallaxLayer
                        speed={1}
                    >
                        <header>
                            <h1>tapusik.eu</h1>
                        </header>
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={.3}
                        speed={0.5}
                        style={{
                            backgroundImage: `url(${jupiter})`,
                            height: '160vh',
                            backgroundSize: 'cover',
                        }}
                    />
                    <ParallaxLayer
                        offset={1}
                    >
                        <div className='categoriesContainer'>
                            <div className='categoriesBtnsContainer'>
                                <div>
                                    <button style={miscBtnActiveCSS} onClick={handleMiscBtn}>Misc</button>
                                </div>
                                {/* <div>
                                    <button style={gamesBtnActiveCSS} onClick={handleGamesBtn}>Games</button>
                                </div> */}
                                <div>
                                    <button style={linksBtnActiveCSS} onClick={handleLinksBtn}>Links</button>
                                </div>
                            </div>

                            {miscContainerVisibility &&
                                <div className='dropDownContainer'>
                                    <a href='/misc/ip-calc'>IP calculator</a>
                                    <a href='/misc/config-gen'>Cisco config generator</a>
                                </div>
                            }

                            {/* {gamesContainerVisibility &&
                                <div className='dropDownContainer'>
                                    <a href='/games/countries-quiz'>Countries quiz</a>
                                </div>
                            } */}

                            {linksContainerVisibility &&
                                <div className='dropDownContainer'>
                                    <a href='https://spse.quickconnect.to/'>SPŠE NAS</a>
                                </div>
                            }
                        </div>
                    </ParallaxLayer>
                </Parallax>
            </main>
        </>
    )
};

export default Root;
