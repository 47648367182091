import { React, useState } from 'react';
import './ipcalc.css';
import Navbar from '../../components/navbar/navbar';

export const IPcalc = () => {
    // const [ idNetOutput, setIdNetOutput ] = useState('192.168.0.0');
    const [ gatewayOutput, setGatewayOutput ] = useState("192.168.0.1");
    const [ firstUsableOutput, setFirstUsableOutput ] = useState("192.168.0.2");
    const [ lastUsableOutput, setLastUsableOutput ] = useState("192.168.0.254");
    const [ broadcastOutput, setBroadcastOutput ] = useState("192.168.0.255");
    const [ numberOfUsableHostsOutput, setNumberOfUsableHosts ] = useState("253");
    const [ maskOutput, setMaskOutput ] = useState("255.255.255.0");
    const [ classIPOutput, setClassIPOutput ] = useState("C");
    const [ classMaskOutput, setClassMaskOutput ] = useState("C");

    const [ idNetInput, setIdNetInput ] = useState("");
    const [ prefixInput, setPrefixInput ] = useState("");

    function ipAdressValidation() {
        const ipCorrectSigns = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipCorrectSigns.test(idNetInput);
    }

    function prefixToMask (prefix) {
        var binaryArray = new Array(32).fill(0);

        for (var i = 0; i < prefix; i++) {
            binaryArray[i] = 1;
        }

        var octets = [];
        for (var j = 0; j < 4; j++) {
            var binaryString = binaryArray.slice(j * 8, (j + 1) * 8).join('');
            var octet = parseInt(binaryString, 2);
            octets.push(octet);
        }
        
        return octets.join('.');
    }

    function addHostsToIP (ip, numHosts) {
        var octets = ip.split('.');
        
        var numericIP = (parseInt(octets[0]) << 24) |
                        (parseInt(octets[1]) << 16) |
                        (parseInt(octets[2]) << 8) |
                        parseInt(octets[3]);
        
        var result = numericIP + numHosts;
        
        var newOctets = [
            (result >> 24) & 0xFF,
            (result >> 16) & 0xFF,
            (result >> 8) & 0xFF,
            result & 0xFF
        ];
        
        return newOctets.join('.');
    }

    function whichIPClass () {
        var octets = idNetInput.split('.');

        if (octets[0] <= 127 && octets[3] <= 255) return "A";
        if (octets[0] <= 191 && octets[3] <= 255) return "B";
        if (octets[0] <= 223 && octets[3] <= 255) return "C";
        if (octets[0] <= 239 && octets[3] <= 255) return "D";
        if (octets[0] <= 255 && octets[3] <= 255) return "E";
    }

    function whichMaskClass() {
        if (prefixInput <= 8) return "A";
        if (prefixInput <= 16) return "B";
        if (prefixInput <= 24) return "C";
        if (prefixInput <= 32) return "Not defined";
    }

    const handleCalculateBtn = (e) => {
        e.preventDefault();
        if (!ipAdressValidation()) return;

        const numberOfHosts = Math.pow(2, 32 - prefixInput);

        setMaskOutput(prefixToMask(prefixInput));
        // setIdNetOutput(idNetInput);
        setNumberOfUsableHosts(numberOfHosts - 3);
        setGatewayOutput(addHostsToIP(idNetInput, 1));
        setFirstUsableOutput(addHostsToIP(idNetInput, 2));
        setLastUsableOutput(addHostsToIP(idNetInput, (numberOfHosts - 2)));
        setBroadcastOutput(addHostsToIP(idNetInput, (numberOfHosts - 1)));
        setClassIPOutput(whichIPClass());
        setClassMaskOutput(whichMaskClass());
    }

    return (
        <>
            <Navbar />
            <main className='ipcalcMain'>
                <div>
                    {/* <div className='singleOutputContainer'>
                        <span>ID NET: </span>
                        <div className='outputWrapper'><span>{idNetOutput}</span></div>
                    </div> */}
                    <div className='singleOutputContainer'>
                        <label htmlFor='gwOut'>Gateway: </label>
                        <output id='gwOut'>{gatewayOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>First Usable: </label>
                        <output id='firstUsableOut'>{firstUsableOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>Last Usable: </label>
                        <output id='firstUsableOut'>{lastUsableOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>Broadcast: </label>
                        <output id='firstUsableOut'>{broadcastOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>Num. of Usable: </label>
                        <output id='firstUsableOut'>{numberOfUsableHostsOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>Mask: </label>
                        <output id='firstUsableOut'>{maskOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>IP Class: </label>
                        <output id='firstUsableOut'>{classIPOutput}</output>
                    </div>
                    <div className='singleOutputContainer'>
                        <label htmlFor='firstUsableOut'>Mask Class: </label>
                        <output id='firstUsableOut'>{classMaskOutput}</output>
                    </div>
                </div>
                <div>
                    <form onSubmit={handleCalculateBtn}>
                        <div className='ipInputContainer'>
                            <label htmlFor="ipInput">Network ID: </label>
                            <input className='ipInput' id='ipInput' onChange={(e) => setIdNetInput(e.target.value)} type="text" placeholder='192.168.0.0' required/>
                            <span>/</span>
                            <input className='prefixInput' id='ipInput' onChange={(e) => setPrefixInput(e.target.value)} type="text" placeholder='24' required/>
                        </div>
                        <div className='submitWrapper'>
                            <input type="submit" value='Calculate' />
                        </div>
                    </form>
                </div>
            </main>
        </>
    ) 
}

export default IPcalc;